// src/app/services/jotform-assistant.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationStart, Event as RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// Interface for window with Jotform agent
interface WindowWithAgent extends Window {
  AgentInitializer?: {
    init: (config: any) => void;
  };
}

@Injectable({
  providedIn: 'root'
})
export class JotformAssistantService implements OnDestroy {
  private agentInitialized = false;
  private routerSubscription: Subscription;
  private allowedRoutes: string[] = ['/agenda', '/login'];
  
  constructor(private router: Router) {
    // Subscribe to router events
    this.routerSubscription = this.router.events
      .pipe(
        filter((event: RouterEvent): event is NavigationStart => event instanceof NavigationStart)
      )
      .subscribe((event: NavigationStart) => {
        
        const shouldShowAssistant = this.shouldShowAssistantOnRoute(event.url);
        
        if (shouldShowAssistant && !this.agentInitialized) {
          this.initializeAssistant();
        } else if (!shouldShowAssistant && this.agentInitialized) {
          this.removeAssistant();
        }
      });
    
    // Check if should initialize on current route
    if (this.shouldShowAssistantOnRoute(this.router.url)) {
      this.initializeAssistant();
    }
  }
  
  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.removeAssistant();
  }
  
  private shouldShowAssistantOnRoute(url: string): boolean {
    return this.allowedRoutes.some(route => url.startsWith(route));
  }
  
  private initializeAssistant(): void {
    const initAgent = () => {
      const windowWithAgent = window as unknown as WindowWithAgent;
      
      if (windowWithAgent.AgentInitializer) {
        windowWithAgent.AgentInitializer.init({
          agentRenderURL: "https://agent.jotform.com/0195488c874274e193a5df57b5d8911d9075",
          rootId: "JotformAgent-0195488c874274e193a5df57b5d8911d9075",
          formID: "0195488c874274e193a5df57b5d8911d9075",
          queryParams: ["skipWelcome=1", "maximizable=1"],
          domain: "https://www.jotform.com",
          isDraggable: false,
          background: "linear-gradient(180deg, #db9991 0%, #e8d8ce 100%)",
          buttonBackgroundColor: "#04569D",
          buttonIconColor: "#F7FFE9",
          variant: false,
          customizations: {
            "greeting": "Yes",
            "greetingMessage": "Olá! Como posso ajudá-lo?",
            "openByDefault": "No",
            "pulse": "Yes",
            "position": "right",
            "autoOpenChatIn": "0"
          },
          isVoice: undefined
        });
        this.agentInitialized = true;
      } else {
        setTimeout(initAgent, 100);
      }
    };
    
    setTimeout(initAgent, 300);
  }
  
  private removeAssistant(): void {
    // Find and remove the agent element
    const agentElement = document.getElementById('JotformAgent-0195488c874274e193a5df57b5d8911d9075');
    if (agentElement) {
      agentElement.remove();
    }
    
    // Also look for any container elements created by Jotform
    const agentContainers = document.querySelectorAll('.jotform-agent-container');
    agentContainers.forEach(container => {
      container.remove();
    });
    
    // Find and remove any modal backdrop elements
    const modalBackdrops = document.querySelectorAll('.modal-backdrop');
    modalBackdrops.forEach(backdrop => {
      backdrop.remove();
    });
    
    // Reset the body classes that might have been added by Jotform
    document.body.classList.remove('modal-open');
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    
    // Reset the initialization flag
    this.agentInitialized = false;
  }
  
  // Public method to manually initialize the assistant
  public show(): void {
    if (!this.agentInitialized) {
      this.initializeAssistant();
    }
  }
  
  // Public method to manually remove the assistant
  public hide(): void {
    if (this.agentInitialized) {
      this.removeAssistant();
    }
  }
  
  // Add a route to the allowed list
  public addAllowedRoute(route: string): void {
    if (!this.allowedRoutes.includes(route)) {
      this.allowedRoutes.push(route);
      
      // If current route is the newly added route, show the assistant
      if (this.router.url.startsWith(route) && !this.agentInitialized) {
        this.initializeAssistant();
      }
    }
  }
  
  // Remove a route from the allowed list
  public removeAllowedRoute(route: string): void {
    const index = this.allowedRoutes.indexOf(route);
    if (index >= 0) {
      this.allowedRoutes.splice(index, 1);
      
      // If current route is the removed route, hide the assistant
      if (this.router.url.startsWith(route) && this.agentInitialized) {
        this.removeAssistant();
      }
    }
  }
}